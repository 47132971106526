import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';

import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import { chienDichApi } from '../../api/chienDichApi';
import { formApi } from '../../api/formApi';
import { formItemApi } from '../../api/formItemApi';
import { formRegisterApi } from '../../api/formRegisterApi';
import { appConst } from '../../app-const';
import { useScreenSizeClass } from '../../utils/media-query';
import { AttendanceSuccess } from '../form-attendance/FormAttendancePage';
import { FormCobobox } from '../form/Editor';
import FormTagBox from '../form/Editor/FormTagbox';
import styles from './FormRegisterPage.module.css';
const FormRegisterPage = ({ isAttendance, dmChienDichId, tsFormId }) => {
  const screenSizeClass = useScreenSizeClass();
  const location = useLocation();

  const params = location.pathname.replace('/form-register/', '').split('/');
  const dm_chiendich_id = dmChienDichId || (params.length > 0 ? parseInt(params[0]) : 0);
  const ts_form_id = tsFormId || (params.length > 1 ? parseInt(params[1]) : 0);

  const [ts_form, set_ts_form] = useState({});
  const [ts_form_items, set_ts_form_items] = useState([]);
  const [ts_form_register_result_id, set_ts_form_register_result_id] =
    useState(0);
  const [chienDich, setChienDich] = useState();
  const { register, trigger, errors } = useForm();

  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    dm_chiendich_id: dm_chiendich_id,
  });

  useEffect(() => {
    if (dm_chiendich_id > 0 && ts_form_id > 0 && ts_form_register_result_id > 0) {
      //lưu cache để điểm danh
      localStorage.setItem(`${dm_chiendich_id}-${ts_form_id}`, ts_form_register_result_id);
    }
  }, [ts_form_register_result_id, ts_form_id, dm_chiendich_id])

  useEffect(() => {
    handleReloadForm();
  }, [ts_form_id]);
  useEffect(() => {
    handleReloadChienDich();
  }, [dm_chiendich_id])
  useEffect(() => {
    if (ts_form && ts_form.id > 0) {
      setFormData({
        ...formData,
        ts_form_id: ts_form.id,
      });
      handleReloadFormItems();
    }
  }, [ts_form]);
  useEffect(() => {
    setFormData({
      ...formData,
      result: ts_form_items.map((x) => {
        return {
          ts_form_item_id: x.id,
          ts_form_editor_id: x.ts_form_editor_id,
          value: '',
        };
      }),
    });
  }, [ts_form_items]);
  const phone_number = useMemo(() => {
    if (formData.result) {
      const phone_number_result = formData.result.find(
        (x) => x.ts_form_editor_id == appConst.formEditorFixed.dien_thoai
      );
      if (phone_number_result) return phone_number_result.value;
    }

    return '';
  }, [formData]);
  useEffect(() => {
    if (isAttendance) {
      hanleAutoFill();
    }

  }, [phone_number, isAttendance])
  const handleReloadChienDich = async () => {
    const res = await chienDichApi.Detail(dm_chiendich_id)
    if (res.is_success) {
      setChienDich(res.data)

    }
  }
  const handleReloadForm = async () => {
    const res = await formApi.Detail(ts_form_id);
    if (res.is_success) {
      set_ts_form(res.data);

    }
  };
  const handleReloadFormItems = async () => {
    const res = await formItemApi.Select(ts_form.id);
    if (res.is_success) {
      set_ts_form_items(res.data);

    }
  };
  const handleValueChanged = (ts_form_item_id, value) => {
    setFormData({
      ...formData,
      result: formData.result.map((x) => {
        if (x.ts_form_item_id == ts_form_item_id) {
          return {
            ...x,
            ts_form_item_id: x.ts_form_item_id,
            value: value,
          };
        } else {
          return { ...x };
        }
      }),
    });
  };
  const handleSubmit = async () => {
    trigger().then(async (isValid) => {
      if (isValid) {
        setIsSaving(true);
        const res = await formRegisterApi.Insert({
          dm_chiendich_id: dm_chiendich_id,
          ts_form_id: formData.ts_form_id,
          phone_number: phone_number,
          is_attendanced: isAttendance || false,
          result: formData.result.map((x) => {
            return {
              ts_form_item_id: x.ts_form_item_id,
              value: x.value,
              ts_form_editor_id: x.ts_form_editor_id,
            };
          }),
        });
        setIsSaving(false);
        if (res.is_success) {
          set_ts_form_register_result_id(res.data);
        }
      }
    });

  };
  const hanleAutoFill = async () => {
    if (dm_chiendich_id > 0 && phone_number != "") {
      const res = await formRegisterApi.SelecDetailListByPhone(0, dm_chiendich_id, phone_number);
      if (res.is_success) {
        setFormData({
          ...formData,
          result: formData.result.map((x) => {
            const existedData = res.data.find(y => y.ts_form_item_id == x.ts_form_item_id)
            return {
              ...x,
              value: existedData ? existedData.value : "",
            };

          }),
        });
      }
    }
  }
  if ((ts_form_register_result_id > 0) && isAttendance) {
    return <AttendanceSuccess />;
  }
  return (
    <div className={styles.container}>
      <div className={styles.form_container}>
        <div className={styles.form + ' ' + screenSizeClass}>

          <div className={styles.cover_img_container} style={{ backgroundImage: `url(${ts_form.cover_img})` }}>
            {/* <img src={ts_form.cover_img} /> */}
            &nbsp;
          </div>
          <div className={styles.form_area}>
            <div className={styles.form_group}>
              <div className={styles.caption}>
                <p>
                  {/* <i className="fas fa-poll" style={{ marginRight: 10 }}></i> */}
                  {ts_form.name}
                </p>
              </div>
              {chienDich && chienDich.top_description_html &&
                <div className={styles.top_description}>
                  <div dangerouslySetInnerHTML={{ __html: chienDich.top_description_html }} />
                </div>
              }
              {(ts_form_register_result_id > 0) && !isAttendance && (

                <div className={styles.result}>
                  <img src="../../../images/thank-you.png" />
                  <p className={styles.thank_caption}>
                    Quý Phụ huynh đã đăng ký thành công.
                  </p>
                  <p className={styles.description}>
                    Phòng tuyển sinh sẽ liên hệ lại với phụ huynh trong thời gian sớm nhất.
                  </p>
                </div>
              )}
            </div>


            {ts_form_register_result_id == 0 && (
              <div>
                {ts_form_items.map((x) => {
                  const existedData = formData.result.find(y => y.ts_form_item_id == x.id)

                  return (
                    <div key={x.id} className={styles.form_group}>
                      <div className={styles.form_label}>{x.label}
                        {x.is_required && <span style={{ color: "red" }}> *</span>}
                      </div>
                      {x.ts_form_editor_type_id == appConst.formEditorType.text_box && (
                        <TextBox
                          stylingMode="underlined"
                          placeholder={x.help_text}
                          value={existedData?.value}
                          onValueChanged={(e) => {
                            if (e.event) {
                              handleValueChanged(x.id, e.value);
                              trigger(`F_${existedData?.ts_form_item_id}`)
                              // trigger();
                            }
                          }}
                        />
                      )}
                      {x.ts_form_editor_type_id == appConst.formEditorType.combobox && (
                        <FormCobobox
                          ts_form_editor_id={x.ts_form_editor_id}
                          value={existedData?.value}
                          onValueChanged={(value) => {
                            handleValueChanged(x.id, value);
                            trigger(`F_${existedData?.ts_form_item_id}`)
                          }}
                        />
                      )}
                      {x.ts_form_editor_type_id == appConst.formEditorType.tagbox && (
                        <FormTagBox
                          ts_form_editor_id={x.ts_form_editor_id}
                          value={JSON.parse(existedData?.value ? existedData?.value : "[]")}
                          onValueChanged={(value) => {
                            console.log({
                              value
                            });
                            handleValueChanged(x.id, JSON.stringify(value));
                            trigger(`F_${existedData?.ts_form_item_id}`)
                          }}
                        />
                      )}

                      {existedData?.value && existedData?.value != 'Invalid date' && x.ts_form_editor_type_id == appConst.formEditorType.date_box && (
                        <DateBox
                          stylingMode="underlined"
                          displayFormat={'dd/MM/yyyy'}
                          placeholder={x.help_text}
                          value={existedData?.value ? moment(existedData?.value, 'DD/MM/YYYY') : ""}
                          onValueChanged={(e) => {
                            if (e.event) {
                              const date = moment(e.value).format('DD/MM/YYYY');
                              handleValueChanged(x.id, date != "Invalid date" ? date : undefined);
                              trigger(`F_${existedData?.ts_form_item_id}`)
                            }
                          }}
                        />
                      )}
                      {(!existedData?.value || existedData?.value == 'Invalid date') && x.ts_form_editor_type_id == appConst.formEditorType.date_box && (
                        <DateBox
                          stylingMode="underlined"
                          placeholder={x.help_text}
                          onValueChanged={(e) => {
                            if (e.event) {
                              const date = moment(e.value).format('DD/MM/YYYY');
                              handleValueChanged(x.id, date);
                              trigger(`F_${existedData?.ts_form_item_id}`)
                            }
                          }}
                        />
                      )}
                      {/* <div className={styles.form_help_text}>{x.help_text}</div> */}
                      <input
                        name={`F_${existedData?.ts_form_item_id}`}
                        className="hide"
                        defaultValue={existedData?.value}
                        ref={register({ required: x.is_required })}
                      ></input>
                      {errors[`F_${existedData?.ts_form_item_id}`] && (
                        <Label basic color="red" pointing>
                          {`Vui lòng điền ${x.help_text}`}
                        </Label>
                      )}
                    </div>
                  );
                })}
                {chienDich && chienDich.bottom_description_html &&
                  <div className={styles.form_group}>
                    <div className={styles.bottom_description}>
                      <div dangerouslySetInnerHTML={{ __html: chienDich.bottom_description_html }} />
                    </div>
                  </div>
                }
                <div className={styles.form_actions}>
                  <button onClick={() => {
                    if (!isSaving) {
                      handleSubmit()
                    }
                  }} className={styles.btnSubmit}>
                    {!isSaving ? `Xác nhận` : `Đang cập nhật ...`}
                  </button>
                  {/* <Button
                    text={'Xác nhận'}
                    width={'100%'}
                    type="default"
                    onClick={handleSubmit}
                    disabled={isSaving}
                  /> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormRegisterPage;
