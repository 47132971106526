import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { truongApi } from '../api/truongApi';
import { useScreenSize } from '../utils/media-query';
import { truongActionTypes } from '../state/reducers/truongReducer';
import { tsDictionaryApi } from '../api/tsDictionaryApi';
import notify from 'devextreme/ui/notify';
export const CommonContext = createContext(null);
export const HttpMethod = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE"
}
export default ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((x) => x.auth);
  const [dictionary, setDictionary] = React.useState(new Map());

  const { isLarge } = useScreenSize();
  const translate = (key) => {
    if (localStorage.language === 'en') {
      // console.log(dictionary);
      if (dictionary.has(key)) {
        return dictionary.get(key);
      }

      return key;
    } else {
      return key;
    }
  };

  const menuItems = useMemo(() => {
    if (user && user.menus) {
      let items = [];
      items = user.menus.map((item) => {
        if (item.path && !/^\//.test(item.path)) {
          item.path = `/${item.path}`;
        }
        return { ...item };
      });
      return [
        // {
        //   name: translate('Trang chủ'),
        //   path: '/home',
        //   icon: 'fas fa-home',
        // },
        ...items,
      ];
    } else {
      return [];
    }
  }, [user]);
  useEffect(() => {
    if (user && user.id > 0) {
      handleReloadTruong();
      handleReloadDictionary();
    }
  }, [user]);
  const handleReloadTruong = async () => {
    const dm_truong_ids = user.campus ? user.campus.map((x) => x.id) : [];
    const res = await truongApi.SelectAll();
    if (res.is_success) {
      const dm_truongs = res.data.filter((x) => dm_truong_ids.includes(x.id));
      dispatch({ type: truongActionTypes.LOAD.SUCESS, payload: dm_truongs });
    }
  };
  const canAccessTo = (api, httpMethod) => {
    return true;
    return user.apis.filter(x => x.method == httpMethod).find(
      (x) =>
        x.url.toUpperCase() === api.toUpperCase() ||
        x.url.toUpperCase() === ('api/' + api).toUpperCase()
    );
  };
  const handleReloadDictionary = async () => {
    const res = await tsDictionaryApi.selectAll();
    if (res.is_success) {
      let _dictionaryMap = new Map();
      res.data.forEach((element) => {
        _dictionaryMap.set(element.vn, element.en);
      });
      setDictionary(_dictionaryMap);
    }
  };

  const showNotify = ({
    text,
    type = 'info' | 'warning' | 'success' | 'error' | 'default',
    time = 3000,
  }) => {
    notify(translate(text), type, time);
  };
  const store = {
    menuItems,
    translate,
    showNotify,
    canAccessTo,
    dm_truongs: [],
  };

  return (
    <CommonContext.Provider value={store}>{children}</CommonContext.Provider>
  );
};
export const useCommonContext = () => useContext(CommonContext);
