import pMinDelay from 'p-min-delay';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import { Loadable } from './components/loadable';
import { LoaderApp } from './components/loader-page';
import './index.css';
import './data-grid.css';
import FormRegisterPage from './pages/form-register/FormRegisterPage';
import './polyfills';
import * as serviceWorker from './serviceWorker';
import { store } from './state/store';

import './dx-styles.scss';
import './style.css';
import './themes/generated/theme.additional.css';
import './themes/generated/theme.base.css';


const pMinDelayTime = 250;
const AppContent = Loadable(() => pMinDelay(import('./App'), pMinDelayTime), { fallback: <LoaderApp /> });
const FormAttendancePageContent = Loadable(() => pMinDelay(import('./pages/form-attendance'), pMinDelayTime), { fallback: <LoaderApp /> });

const App = () => {
  const location = useLocation();
  if (location.pathname.includes("/form-register/")) {
    if (location.pathname.includes('attendance')) {
      return <FormAttendancePageContent />
    }
    return <FormRegisterPage />
  } else {
    return <AppContent />
  }
}


ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <Router>
      <App />
    </Router>

  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
