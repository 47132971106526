import { apiHelper } from "./api_helper";

export const FORM_REGISTER_API_END_POINT = "form-result";
export const FORM_REGISTER_DETAIL_END_POINT = "form-result-detail";
export const FORM_REPORT_END_POINT = "report-lead";


export const formRegisterApi = {
    Insert: (data) => apiHelper.postWithoutCheckToken(`${FORM_REGISTER_API_END_POINT}`, data),
    SendEmail: (data) => apiHelper.postWithoutCheckToken(`${FORM_REGISTER_API_END_POINT}/send-email`, data),
    Select: (dm_coso_id, dm_chiendich_id, nam_hoc) => 
    apiHelper.get(`${FORM_REGISTER_API_END_POINT}/co-so/${dm_coso_id}/chien-dich/${dm_chiendich_id}/nam-hoc/${nam_hoc}`),
    InsertDetail: (data) => apiHelper.post(`${FORM_REGISTER_DETAIL_END_POINT}`, data),
    UpdateDetail: (data) => apiHelper.put(`${FORM_REGISTER_DETAIL_END_POINT}`, data),
    SelecDetailListByResult: (data) => apiHelper.post(`${FORM_REGISTER_DETAIL_END_POINT}/results`, data),
    UpdateResult: (data) => apiHelper.put(`${FORM_REGISTER_API_END_POINT}/update-result`, data),
    UpdateHocSinh: (data) => apiHelper.put(`${FORM_REGISTER_API_END_POINT}/update-hocsinh`, data),
    UpdateInvite: (data) => apiHelper.put(`${FORM_REGISTER_API_END_POINT}/update-invite`, data),
    SelecDetailList: (dm_coso_id, dm_chiendich_id) => apiHelper.get(`${FORM_REGISTER_DETAIL_END_POINT}/co-so/${dm_coso_id ?? 0}/chien-dich/${dm_chiendich_id ?? 0}`),
    SelecDetailListByPhone: (dm_coso_id, dm_chiendich_id, phone_number) =>
        apiHelper.getWithoutCheckToken(`${FORM_REGISTER_DETAIL_END_POINT}/co-so/${dm_coso_id ?? 0}/chien-dich/${dm_chiendich_id ?? 0}/phone/${phone_number}`),
    Attendance: (data) => apiHelper.postWithoutCheckToken(`${FORM_REGISTER_API_END_POINT}/attendance`, data),
    ValidateImport: (data, nam_hoc, dm_coso_id) => apiHelper.put(`${FORM_REGISTER_API_END_POINT}/validate-import/nam-hoc/${nam_hoc}/co-so/${(dm_coso_id ?? 0)}`, data),
    Import: (data, nam_hoc, dm_coso_id) => apiHelper.put(`${FORM_REGISTER_API_END_POINT}/import/nam-hoc/${nam_hoc}/co-so/${(dm_coso_id ?? 0)}`, data),
    SendEmailInvidate: ({ dm_coso_id, ts_register_result_ids, thoi_gian, dia_diem, dm_chiendich_id }) => apiHelper.post(`${FORM_REGISTER_API_END_POINT}/send-invite`, {
        ts_register_result_ids,
        thoi_gian,
        dia_diem,
        dm_chiendich_id,
        dm_coso_id
    }),
    SendEmailMoiGhiDanh: ({ dm_coso_id, ts_register_result_ids, dm_chiendich_id }) => apiHelper.post(`${FORM_REGISTER_API_END_POINT}/send-moi-ghi-danh`, {
        ts_register_result_ids,
        dm_chiendich_id,
        dm_coso_id
    }),
    SelectInformation: (dm_coso_id, nam_hoc) => apiHelper.get(`${FORM_REGISTER_DETAIL_END_POINT}/nam-hoc/${nam_hoc}/co-so/${dm_coso_id}`),
    ReportLead: (dm_coso_id, tu_van_vien_id, from_date, to_date) =>
        apiHelper.post(`${FORM_REPORT_END_POINT}`, {
            dm_coso_id,
            tu_van_vien_id,
            from_date,
            to_date
    }),

}