export const appConst = {
    formEditorType: {
        text_box: 1,
        combobox: 2,
        date_box: 3,
        tagbox: 4
    },
    formEditorFixed: {
        ho_ten: 1,
        dien_thoai: 2,
        email: 3,
        gioi_tinh: 4,
        ho_ten_hs: 8,
        gioi_tinh_hs: 9,
        truong_dang_hoc: 10,
        ngay_sinh_hs: 11,
        khoi_lop: 20

    }
}

